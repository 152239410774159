type NodeArrays =
  | { edges?: ({ node?: Record<string, any> | null | undefined } | null)[] | null | undefined }
  | undefined
  | null;

export type ConnectionResultToNodeArray<T extends NodeArrays> = NonNullable<
  NonNullable<NonNullable<NonNullable<T>['edges']>[number]>['node']
>[];

export function connectionResultToNodeArray<T extends NodeArrays>(connectionResult: T) {
  const array = connectionResult?.edges || [];

  const nodes = array.map((item: any) => item.node);

  return nodes as ConnectionResultToNodeArray<T>;
}
